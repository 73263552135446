<template lang="pug">
b-row
  b-col(v-if="!confirmationPage.route" cols="12")
    div
      form-wizard.wizard-vertical.mb-3(
        :color="colors.primary"
        :title="null",
        :subtitle="null",
        layout="horizontal",
        :finish-button-text="$t('message.submit')",
        :back-button-text="$t('message.previous')",
        :next-button-text="$t('message.next')",
        @on-complete="formSubmitted",
        @on-change="handleTabChange",
        ref="myForm"
      )

        // Information
        tab-content(v-if="information_module" :title="$t('message.information')" class="mb-2" )
          b-row
            b-col.mb-2.mt-2(cols="12")
              // Action Buttom
              b-row
                b-col(md="4" cols="12" class="w-100")
                  h6
                    | {{ $t('message.info_daily_dialogue') }}
                  b-button(class="w-100" :class="activeButton === 'communications' ? 'activeClassButton' : 'optionButton'" @click="handleActiveButton('communications')")
                    span {{ $t('message.internalCommunications') }}
                b-col(md="4" cols="12" class="mt-md-0 mt-2")
                  b-button(class="w-100" :class="activeButton === 'opinions' ? 'activeClassButton' : 'optionButton'" style="margin-top: 1.6rem", @click="handleActiveButton('opinions')")
                    span {{$t('message.customersOpinion') }} 
                b-col(md="4" cols="12" class="mt-md-0 mt-2")
                  h6
                    | {{ $t('message.selectLocation') }}:
                  b-form-group(label-for="v-location", :description="$t('message.where_meeting_held')")
                    validation-provider(:name="$t('label.Location')", rules="required", v-slot="{ errors }")
                      v-select#v-location(
                        v-model="meeting.location",
                        :options="locations",
                        :selectable="(option) => !option.value.includes('select_value')",
                        label="title"
                      )
                      small(class="text-danger") {{ errors[0] }}
            // Communication
            b-col.mb-2.mt-2(cols="12" v-if="activeButton === 'communications'")
              h5.text-primary.mb-0
                | {{ $t('message.communicationsFound') }}: {{ communications.length }}
              b-row(class="mt-2 pb-1 border-bottom-primary")
                b-col(md="5")
                  h6.text-primary      
                    | {{ $t('label.date') }}:
                  span
                    | {{  actualDateToShow }}
                b-col(md="4" class="mt-md-0 mt-2")
                  h6.text-primary       
                    | {{ $t('message.tableHeader.location') }}:
                  b-form-group(label-for="v-location")
                    span
                        |  {{ filteredLocation }}
              // Loader
              div(v-if="isLoading" bg-variant="transparent", border-variant="secondary" style="margin-top: 20rem")
                b-card-body.text-center
                  loading(
                    :active="true" 
                    :is-full-page="false"
                    :color="colors.primary"
                    :opacity="0"
                )
              div(v-else="!isLoading" class="mt-md-0 mt-2")
                b-col(cols="12" v-if="communications.length > 0" class="px-0")
                  b-row(v-for="(communication, index) in communications" :key="index" class="d-flex justify-content-between border-bottom-primary")                   
                    // Title
                    b-col(md="12" class="mb-2 mt-1")
                      h6.text-primary(style="font-weight: bold;")
                          | {{ $t('message.tableHeader.title') }}:
                      span
                        | {{ communication.title }}

                    b-col(md="5" class="mb-1")
                      b-row(class="mr-2")                              
                        // Date from
                        b-col(md="4" class="mb-2")
                          h6.text-primary(style="font-weight: bold;")
                            | {{ $t('message.tableHeader.dateFrom') }}:
                          span
                            | {{ communication.dateFrom }}

                        // Date to    
                        b-col(md="4" class="mb-1")
                          h6.text-primary(style="font-weight: bold;")
                            | {{ $t('message.tableHeader.dateTo') }}:
                          span
                            | {{ communication.dateTo }}
                      // Image
                      b-col(md="12" class="mx-0 px-0" style="margin-bottom: 2rem;")
                        h6.text-primary(style="font-weight: bold;")
                          | {{ $t('message.tableHeader.img') }}:
                        div(v-if="communication.imageKey" @click="openImage(communication.imageKey)" class="image-container")
                          img.img-preview(:src="communication.src" alt="img")
                          span.hover-text
                            | {{ $t('message.tableHeader.fullSize') }}
                        div(v-else class="image-container")
                          img(:src="require('@/assets/images/elements/no-image.jpg')", alt="img", class="img-preview")
                          span.no-image {{ $t('happiness.no_image') }}

                    b-col(md="7" class="mb-2" style="border-left: 1px solid #ccc;")
                      // Detail
                      h6.text-primary(style="font-weight: bold;")
                        | {{ $t('message.tableHeader.detail') }}:
                      div.text-justify
                        p(v-html="communication.detail")

                // No data message
                b-col(v-else cols="12" class="mt-3 p-0")
                  h6.no-data-message
                    | {{ $t('message.no_records') }}

            // Opinions
            b-col.mb-2.mt-2(cols="12" v-else)
              h5.text-primary.mb-0
                | {{ $t('message.customersOpinionsFound') }}: {{ customersOpinions.length }}
              b-row(class="mt-2 pb-1 border-bottom-primary")
                b-col(md="5")
                  h6.text-primary      
                    | {{ $t('label.date') }}:
                  span
                    | {{ sevenDaysBeforeToShow }} - {{ actualDateToShow }}
                b-col(md="4" class="mt-md-0 mt-2")
                  h6.text-primary       
                    | {{ $t('message.tableHeader.location') }}:
                  b-form-group(label-for="v-location")
                    span
                        |  {{ filteredLocation }}
              // Loader
              div(v-if="isLoading" bg-variant="transparent", border-variant="secondary" style="margin-top: 20rem")
                b-card-body.text-center
                  loading(
                    :active="true" 
                    :is-full-page="false"
                    :color="colors.primary"
                    :opacity="0"
                )
              div(v-show="!isLoading" class="mt-md-0 mt-2")
                b-col(cols="12" v-if="customersOpinions.length > 0" class="px-0")
                  b-table(
                      class="mt-2"
                      :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
                      show-empty=""
                      :items="paginatedCustomersOpinions"
                      hover
                      responsive
                      :fields="tableColumns"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="isSortDirDesc"
                    )
                      template(#cell(location)="data")
                        div {{ data.item.location.location }}
                      template(#cell(feedback)="data")
                        div(style="font-weight: 700" :class="`text-${data.item.textColor}`")
                          | {{ $t(`label.${data.item.feedback}`) }}
                      template(#cell(detail)="data")
                        div(v-if="opinionId === data.item._id" v-html="data.item.detail")
                        div(v-else v-html="data.item.croppedDetail ? data.item.croppedDetail : data.item.detail")
                      template(#cell(actions)="data")
                        div.flex.flex-column
                          template(v-if="data.item.croppedDetail")
                            ViewButton(v-if="opinionId !== data.item._id" variant="flat-primary" @clicked="handleView(data.item._id)")
                            XButton(v-else @clicked="handleView(data.item._id)")
                          b-modal(
                            :id="`modal ${data.item._id}`"
                            centered
                            no-close-on-backdrop
                            modal-class="modal-danger"
                            ok-variant="danger"
                            cancel-variant="outline-secondary"
                            :title="$t('message.confirm_action')"
                            :ok-title="$t('message.delete')"
                            :cancel-title="$t('message.cancel')"
                            @ok="deleteCustomerOpinion(data.item._id)"
                          ) {{ $t('message.confirm_delete_customerOpinion') }}
                  // Pagination
                  b-col(v-if="isOnline && customersOpinions.length > 0" cols="12" class="d-flex justify-content-between flex-wrap")
                    .mb-1
                      b-form-group.mb-0
                        label.d-inline-block.text-sm-left.mr-50 {{ $t('message.per_page') }}
                        b-form-select(
                          id="perPageSelect"
                          v-model="perPage"
                          size="sm"
                          :options="[5,10,15,20,25,30,40,50,100]"
                          class="w-50"
                        )
                    .mb-1
                      span.text-muted {{ showingMessage }}
                    b-pagination(
                      v-model="currentPage"
                      :total-rows="totalOpinions"
                      :per-page="perPage"
                      first-number=""
                      last-number=""
                      prev-class="prev-item"
                      next-class="next-item"
                    )
                // No data message
                b-col(v-else cols="12" class="mt-3 p-0")
                  h6.no-data-message
                    | {{ $t('message.no_records') }}

        // Welcome
        tab-content(:title="$t('message.welcome')", :before-change="() => validateTab(welcomeTab)")
          validation-observer(ref="welcomeTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.welcome_to_daily_dialogue') }}
                small.text-muted
                  | {{ $t('message.fill_to_start_meeting') }}
              b-col(v-if="information_module" md="12" class="mb-1 d-flex flex-column")
                label      
                  | {{ $t('label.Location') }}:
                h5
                  |  {{ filteredLocation }}
              b-col(md="6")
                b-form-group(
                  :label="$t('message.tableHeader.date')",
                  label-for="v-date"
                )
                  b-form-datepicker#v-date(v-model="meeting.date", :disabled="daily_dialogue_date_disabled")
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.start') }}
                validation-provider(:name="$t('label.start')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingStart",
                      type="time",
                    )
                  small(class="text-danger") {{ errors[0] }}

              b-col(v-if="!information_module" md="6")
                b-form-group(
                  :label="$t('label.Location')",
                  label-for="v-location",
                  :description="$t('message.where_meeting_held')"
                )
                  validation-provider(:name="$t('label.Location')", rules="required", v-slot="{ errors }")
                    v-select#v-location(
                      v-model="meeting.location",
                      :options="locations",
                      :selectable="(option) => !option.value.includes('select_value')",
                      label="title"
                    )
                    small(class="text-danger") {{ errors[0] }}

        // Happiness
        tab-content(:title="$t('Mood')", :before-change="() => validateTab(happinessTab)")
          validation-observer(ref="happinessTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.how_team_feeling') }}
                small.text-muted
                  | {{ $t('message.count_amount_happiness') }}
              b-col(md="4", class="d-flex flex-column")
                img(v-if="useBatteryIcons", :src="batteryIcons.happy", width="100", height="100", class="align-self-center", alt="Battery 1")
                feather-icon.text-success.stroke-current(v-else, icon="SmileIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.happy')", label-for="v-happy")
                  validation-provider(:name="$t('label.happy')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('happy','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.happy", style="text-align:center", :disabled="mood_only_by_survey")
                      b-input-group-append(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('happy','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ happyPercentage }}%
              b-col(md="4", class="d-flex flex-column my-1 my-md-0")
                img(v-if="useBatteryIcons", :src="batteryIcons.neutral", width="100", height="100", class="align-self-center", alt="Battery 2")
                feather-icon.text-warning.stroke-current(v-else, icon="MehIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.neutral')", label-for="v-neutral")
                  validation-provider(:name="$t('label.neutral')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('neutral','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.neutral", style="text-align:center", :disabled="mood_only_by_survey")
                      b-input-group-append(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('neutral','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ neutralPercentage }}%
              b-col(md="4", class="d-flex flex-column")
                img(v-if="useBatteryIcons", :src="batteryIcons.sad", width="100", height="100", class="align-self-center", alt="Battery 3")
                feather-icon.text-danger.stroke-current(v-else, icon="FrownIcon", size="100", class="align-self-center")
                b-form-group(:label="$t('label.Sad_Angry')", label-for="v-sad-angry")
                  validation-provider(:name="$t('label.Sad_Angry')", rules="required|integer|min_value:0|max_value:100", v-slot="{ errors }")
                    b-input-group
                      b-input-group-prepend(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('sad','remove')")
                          feather-icon(icon="MinusIcon")
                      b-form-input(type="number", v-model="meeting.sad", style="text-align:center", :disabled="mood_only_by_survey")
                      b-input-group-append(v-if="!mood_only_by_survey")
                        b-button(variant="outline-primary", @click="updateHappiness('sad','add')")
                          feather-icon(icon="PlusIcon")
                    small(class="text-danger") {{ errors[0] }}
                div(class="align-self-center")
                  | {{ sadPercentage }}%
            b-row.mt-3
              b-button(v-if="comments.length" class="ml-1 mb-2" variant="outline-primary" size="sm" @click="() => showComments = !showComments")
                | {{ showComments ? $t('message.hideComments') : $t('message.showComments') }}
              b-col.mb-2(v-if="showComments && comments.length", cols="12")
                b-table-simple(hover, small, caption-top, responsive)
                  b-thead
                      b-tr
                        th {{ $t('message.tableHeader.state') }}
                        th {{ $t('message.tableHeader.comments') }}
                      b-tr(v-for="(comment, index) in comments", :key="index")
                        b-td(v-if="comment.comment" :class="stateVariants[comment.answer]") {{ $t(`happiness.${comment.answer}`) }}
                        b-td(v-if="comment.comment" :class="stateVariants[comment.answer]") {{ comment.comment }}
              b-col.my-1(v-if="isOnline", cols="12")
                b-button(variant="primary", :disabled="!meeting.location || isFetchingSurveyResults", @click="updateHappinessFromSurvey")
                  | {{ $t('message.get_survey_results') }}
                span.ml-1(v-show="isFetchingSurveyResults")
                  | {{ $t('message.loading') }}

        // attendees
          tab-content(title="Attendees")
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | Attendees
                small.text-muted Enter the correspoding user(s) for each level of responsability.

              add-attendee(@update-attendees="updateAttendees")

        // Recognitions
        tab-content(:title="$t('label.recognitions')", :before-change="() => validateTab(recognitionsTab)")
          validation-observer(ref="recognitionsTab", tag="form")  
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.team_done_well') }}
                small.text-muted
                  | {{ $t('message.recognition_for_efforts') }}

              add-recognition(
                :workers="workersTotalNotMapped",
                :motives="motives",
                @update-recognitions="updateRecognitions",
              )

        // Improvements
        tab-content(:title="$t('label.improvements')", :before-change="() => validateTab(improvementsTab)")
          validation-observer(ref="improvementsTab", tag="form")   
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.what_improved') }}
                small.text-muted
                  | {{ $t('message.get_feedback') }}

              add-improvement(
                :domains="domains",
                :workers="workersTotalNotMapped",
                :metadata="metadataNotMapped"
                @update-improvements="updateImprovements",
              )

        // Finish
        tab-content(:title="$t('message.finish')", :before-change="() => validateTab(finishTab, true)", :id="'finishTab'", :tab-id="'finishTab'" class="mb-1")
          validation-observer(ref="finishTab", tag="form") 
            b-row
              b-col.mb-2(cols="12")
                h5.mb-0
                  | {{ $t('message.daily_dialogue_end') }}
                small.text-muted
                  | {{ $t('message.complete_end_time') }}
              b-col(md="6")
                b-form-group(
                  :label="$t('message.tableHeader.date')",
                  label-for="v-date"
                )
                  b-form-datepicker#v-date(v-model="meeting.date", :disabled="true")

              b-col(md="6")
                b-form-group(
                  :label="$t('Image')",
                  label-for="v-image",
                )
                  b-button.mr-1(
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="info",
                    @click="uploadImage()"
                  )
                    | {{ $t('message.upload') }}
                  image-compressor(
                    style="display: none",
                    :done="getImgCompressed",
                    ref="compressor"
                  )
                  small(v-if="imgData.fileInfo")
                    | {{imgData.fileInfo.name}}
                  small(v-else-if="daily_dialogue_image_required", class="text-danger")
                    | {{ $t('message.upload_image_required') }}
                  
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.start') }}
                validation-provider(:name="$t('label.start')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingStart",
                      type="time",
                      :disabled="true"
                    )
                  small(class="text-danger") {{ errors[0] }}
              b-col.mb-1.mb-md-0(md="3")
                label
                  | {{ $t('label.end') }}
                validation-provider(:name="$t('label.end')", rules="required", v-slot="{ errors }")
                  b-input-group
                    b-form-input(
                      v-model="meetingEnd",
                      type="time",
                    )
                  small(class="text-danger") {{ errors[0] }}
  b-col(v-else cols="12")
    b-card(
      class="text-primary d-flex flex-column text-center mb-0"
      style="height: 70vh !important; padding-top: 10rem"
    )
      feather-icon(
        icon="CheckCircleIcon"
        size="30"
        class="text-success"
      )
      h4(class="text-primary mt-2") {{ $t('action_completed') }}
      h5(class="text-secondary mt-2") {{ $t(`${confirmationPage.msg}`) }}
      b-button(
        @click="$router.push(confirmationPage.route)"
        variant="primary"
        class="mt-2"
      ) Ok


</template>

<script>
import vSelect from "vue-select"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { FormWizard, TabContent } from "vue-form-wizard"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BFormDatepicker, BFormTimepicker, BFormSpinbutton, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BCardBody, BTable, BPagination, BTableSimple, BThead, BTr, BTd } from "bootstrap-vue"
import AddAttendee from "./AddAttendee.vue"
import AddRecognition from "./AddRecognition.vue"
import AddImprovement from "./AddImprovement.vue"
import i18n from "@/libs/i18n"
import { ref, onMounted, watch, computed } from "@vue/composition-api"
import useCommon from '@/views/organization/useCommon'
import store from "@/store"
import { useRouter } from '@core/utils/utils'
import useNotifications from '@/composables/useNotifications'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer, min_value, max_value } from "@validations"
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Ripple from 'vue-ripple-directive'
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import awsConnection from '@/views/habit/aws'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import useCommunications from '.././communications/useCommunications'
import useCustomersOpinions from '@/views/habit/customersOpinions/useCustomersOpinions'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import XButton from '@/views/components/Shared/Buttons/XButton.vue'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'
import Battery1 from '@/assets/images/svg/Battery1.svg'
import Battery2 from '@/assets/images/svg/Battery2.svg'
import Battery3 from '@/assets/images/svg/Battery3.svg'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormTimepicker,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCardBody,
    BTable,
    BPagination,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    vSelect,
    ToastificationContent,
    AddAttendee,
    AddRecognition,
    AddImprovement,
    ValidationObserver,
    ValidationProvider,
    ImageCompressor,
    Loading,
    ViewButton,
    XButton,
    
  },
  directives: {
    Ripple,
  },
  setup() {
    const { router } = useRouter()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload, sendEmail } = awsConnection()
    const { getWorkersTotalForDropDown, workersTotalNotMapped, getLocationsForDropDown, locations, getMotivesForDropDown, motives, getMetadataForDropDown, metadataNotMapped, getSupervisorsForDropDown, supervisorsOnlyNames, handleError, storeDataToUpload, formatDatePicker, openImage } = useCommon()
    const { getEmailImprovementTemplate, formatEmailDate } = useCommonTodo()
    const { getDomains, updateDailyDialogueWithKey } = useCommonDashboards()
    const { listCommunications, communications, isLoading } = useCommunications()
    const { listCustomersOpinions, customersOpinions, currentPage, perPage, tableColumns, sortBy, isSortDirDesc, opinionId, handleView, showingMessage, totalOpinions } = useCustomersOpinions()
    const { createItem, createItems, getItem, getItems, updateItems, ObjectId } = realmConnection()
    const userData = store.state?.userStore?.userData;
    const worker_id = userData.role !== "admin" ? userData.worker_id.$oid : null;
    const client_id = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocation = (userData.role !== "admin" && userData.locations?.length) ? userData.locations[0] : null;
    const { daily_dialogue_image_required, daily_dialogue_date_disabled, mood_only_by_survey } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const isSubmitting = ref(false)
    const welcomeTab = ref(null)
    const happinessTab = ref(null)
    const recognitionsTab = ref(null)
    const improvementsTab = ref(null)
    const finishTab = ref(null)
    const { default_language, information_module } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const actualDate = new Date()
    const actualDateToShow = actualDate.toLocaleDateString(`${default_language || 'en'}-US`)
    const sevenDaysBefore = new Date(actualDate)
    sevenDaysBefore.setDate(actualDate.getDate() - 7)
    const sevenDaysBeforeToShow = sevenDaysBefore.toLocaleDateString(`${default_language || 'en'}-US`)
    const actualDatePlus15Min = ref(new Date())
    // actualDatePlus15Min.value.setMinutes(actualDate.getMinutes() + 15 );
    const isOnline = computed(() => store.state.app.isOnline)
    const compressor = ref(null)
    const imgData = ref({})
    const isFetchingSurveyResults = ref(false)
    const showComments = ref(false)
    const confirmationPage = ref({ route: '', msg: ''})
    const domains = computed(() => getDomains(client_id))
    const filteredLocation = ref('')
    const comments = ref([])
    const stateVariants = { happy: 'text-success', neutral: 'text-warning', sad: 'text-danger' }
    const selectedLocation = ref(userLocation)
    const useBatteryIcons = ref(false)
    const batteryIcons = { happy: Battery1, neutral: Battery2, sad: Battery3 }

    const meeting = ref({
      date: actualDate,
      happy: 0,
      neutral: 0,
      sad: 0,
      recognitions: [],
      improvements: [],
      location: userLocation,
    })

    // These variables are defined separately in order to be watched
    const meetingStart = ref(
      actualDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )

    const meetingEnd = ref(
      actualDatePlus15Min.value.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }))
    
    const totalHappiness = computed(() => parseInt(meeting.value.happy) + parseInt(meeting.value.neutral) + parseInt(meeting.value.sad))
    const happyPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.happy / totalHappiness.value * 100) : 0)
    const neutralPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.neutral / totalHappiness.value * 100) : 0)
    const sadPercentage = computed(() => totalHappiness.value ? Math.round(meeting.value.sad / totalHappiness.value * 100) : 0)

    // This function needs to be defined with "function handle..." instead of "const handle..." in order to use "this.$refs"
    function handleTabChange(oldTab, newTab) {
      const tabs = this.$refs.myForm.tabs;
      const lastIndex = tabs.length - 1;
      if (lastIndex === newTab) {
        actualDatePlus15Min.value = new Date();
        meetingEnd.value = actualDatePlus15Min.value.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      }
    }

    watch(locations, () => {
      if (!meeting.value.location && locations.value.length) meeting.value.location = locations.value[0]
    })

    watch([meeting, locations], () => {
      if (meeting.value.location?.value !== selectedLocation.value?.value) {
        getHappinessIcons(meeting.value.location?.value)
        selectedLocation.value = meeting.value.location
      }
      listCustomersOpinions('new', actualDate, meeting.value.location?.value)
      listCommunications(actualDate, meeting.value.location)
      filteredLocation.value = meeting.value.location?.title
    },{ deep: true })
    
    watch(meetingStart, () => {
        // Update meeting end time
        const [hoursString, minutesString] = meetingStart.value.split(":")
        const hours = parseInt(hoursString)
        const minutes = parseInt(minutesString)
        let endHours = minutes < 45 ? hours : hours + 1
        let endMinutes = minutes < 45 ? minutes + 15 : minutes - 45
        if (endHours === 24) endHours = 0
        if (endHours < 10) endHours = `0${endHours}`
        if (endMinutes < 10) endMinutes = `0${endMinutes}`
        const endTime = `${endHours}:${endMinutes}`
        meetingEnd.value = endTime
    })
    
    onMounted(async () => {
      getLocationsForDropDown()
      getWorkersTotalForDropDown("notMapped")
      getMotivesForDropDown()
      getSupervisorsForDropDown("onlyNames")
      getMetadataForDropDown({category: "improvement", option: "notMapped"})
      getHappinessIcons(meeting.value.location?.value)
    })
  
    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date and column width for UI purposes
          if (e.name === "creation_date") {
            e.answer = `${actualDate.getFullYear()}-${actualDate.getMonth() < 9 ? "0" + (actualDate.getMonth() + 1) : actualDate.getMonth() + 1}-${actualDate.getDate() < 10 ? "0" + actualDate.getDate() : actualDate.getDate()}`
            e.columnWidth = 2
          }
          // Set default instance leader and update instance leader options with supervisors
          if (e.name === "instance_leader") {
            e.answer = userData.username
            e.options = supervisorsOnlyNames.value
          }
        })
      }
    })

    watch(supervisorsOnlyNames, val => {
      if (metadataNotMapped.value && metadataNotMapped.value.length) {
        metadataNotMapped.value.forEach(e => {
          // Update instance leader options with supervisors
          if (e.name === "instance_leader") {
            e.options = val
          }
        })
      }
    })

    const updateRecognitions = (recognitions) => {
      meeting.value.recognitions = recognitions;
    }

    const updateImprovements = (improvements) => {
      meeting.value.improvements = improvements;
    }

    const updateHappiness = (state, change) => {
      if (change === "add" && meeting.value[state] < 100) meeting.value[state]++
      else if (change === "remove" && meeting.value[state] > 0) meeting.value[state]--
    }

    const formSubmitted = async () => {
      if (isSubmitting.value) return
      isSubmitting.value = true

      const dateTime = formatDatePicker(meeting.value.date)

      const payload = {
        client_id: client_id,
        created_by: worker_id,
        date: dateTime,
        start: meetingStart.value.length === 4 ? `0${meetingStart.value}` : meetingStart.value,
        end: meetingEnd.value.length === 4 ? `0${meetingEnd.value}` : meetingEnd.value,
        happy: parseInt(meeting.value.happy),
        neutral: parseInt(meeting.value.neutral),
        sad: parseInt(meeting.value.sad),
        location: meeting.value.location.value,
        improvements: meeting.value.improvements?.map((i) => {
          const improvement = {
            client_id: client_id,
            note: i.note,
            domain: i.domain,
            assignee: i.assignee?._id,
            description: '',
            tags: [i.domain],
            completed: false,
            deleted: false,
            important: false,
            metadata: i.metadata?.map(metadata => ({
              name: metadata.name,
              type: metadata.type,
              answer: metadata.answer,
            })) || []
          }

          if (i.dueDate) improvement.dueDate = new Date(`${i.dueDate.slice(0, 10)} 12:00:00`)

          return improvement
        }) || [],
        recognitions: meeting.value.recognitions?.map((i) => ({
          client_id: client_id,
          note: i.note,
          worker: i.worker?._id,
          motive: i.motive,
          created_by: worker_id,
          date: dateTime
        })) || [],
      };

      try {
        const surveyData = await getSurveyResults()
        if (surveyData?.length) {
          payload.employeeSurveys = surveyData.map(e => e._id)
        }
      } catch (error) {
        console.log(error)
      }

      if (isOnline.value) {
        postDailyDialogue(payload)  
      }
      else {
        payload.imgData = imgData.value
        payload.improvementsNotMapped = meeting.value.improvements

        // The data stored in IndexedDB can't have ObjectIds, they must be all in string format
        storeDataToUpload('dialoguesToUpload', payload)

        // showSuccessMessage(i18n.t('message.form_stored'))
        // router.push({ name: 'apps-calendar' })
        showConfirmationPage({ name: 'apps-calendar' }, 'message.form_stored')
      }
    }

    const postDailyDialogue = async (payload) => {
      try {
        payload.client_id = ObjectId(payload.client_id)
        payload.created_by = ObjectId(payload.created_by)
        payload.location = ObjectId(payload.location)
        if (payload.employeeSurveys) payload.employeeSurveys = payload.employeeSurveys.map(e => ObjectId(e))

        // Create recognitions
        if (payload.recognitions?.length) {
          const recognitionsToCreate = payload.recognitions.map(e => ({
            ...e,
            client_id: ObjectId(e.client_id),
            worker: ObjectId(e.worker),
            motive: ObjectId(e.motive),
            created_by: ObjectId(e.created_by),
          }))

          const { insertedIds: newRecognitionIds } = await createItems({ collection: 'recognition', payload: recognitionsToCreate })
          payload.recognitions = newRecognitionIds
        }

        // Create improvements
        if (payload.improvements?.length) {
          const improvementsToCreate = payload.improvements.map(e => ({
            ...e,
            client_id: ObjectId(e.client_id),
            assignee: ObjectId(e.assignee),
          }))

          const { insertedIds: newImprovementIds } = await createItems({ collection: 'improvement', payload: improvementsToCreate })
          payload.improvements = newImprovementIds
        }

        const { insertedId } = await createItem({ collection: 'meeting', payload })
        if (!insertedId) throw new Error('Item not created')

        // Mark location's employee survey results as read
        const meetingDateZeroHours = formatDatePicker(meeting.value.date)
        meetingDateZeroHours.setHours(0, 0, 0, 0)
        const meetingDateNextDay = new Date(meetingDateZeroHours)
        meetingDateNextDay.setDate(meetingDateNextDay.getDate() + 1)

        const query = {
          location: ObjectId(meeting.value.location.value),
          createdAt: { $gte: meetingDateZeroHours, $lt: meetingDateNextDay }
        }
        const action = { $set: { read: true } }

        try {
          await updateItems({ collection: 'employee_survey', query, action })
        } catch (error) {
          console.log(error)
        }
          
        // Upload image to AWS and then update the daily dialogue in MongoDB with the AWS image key
        const dailyDialogueId = insertedId.toString()
        const { fileInfo, destinationFolder } = imgData.value
        if (fileInfo) {
          singleUpload(fileInfo, destinationFolder)
            .then((key) => updateDailyDialogueWithKey(dailyDialogueId, key))
            .catch((err) => console.log(err))
        }
        
        // Send email to assignees with the details of the improvements opportunity created
        meeting.value.improvements?.forEach(e => {
          if (e.assignee?.email) {
            const subject = i18n.t('message.improvement_opportunity_assigned')
            let bodyData = {
              name: e.assignee?.name,
              title: e.note,
              tags: e.domain ? i18n.t(`domain.${e.domain}`) : '',
              creator: userData.fullName,
              dueDate: formatEmailDate(e.dueDate),
            }
            if (e.metadata?.length) {
              e.metadata.forEach(e => {
                if (e.name === "creation_date") e.answer = formatEmailDate(e.answer)
                Object.assign(bodyData, {[e.name]: e.answer})
              })
            }
            const body = getEmailImprovementTemplate(bodyData)
            
            sendEmail([e.assignee.email], subject, body)
              .then((response) => {
                if (response.MessageId) showSuccessMessage(i18n.t('message.email_send_improvement_success'))
              })
              .catch((err) => {
                console.log(err)
                showErrorMessage(i18n.t('message.email_send_improvement_error'))
              })
          }
        })
        
        // Redirect to happiness dashboard
        // router.push({ name: 'habit-happiness-dashboard' })
        showConfirmationPage({ name: 'habit-happiness-dashboard' }, 'message.daily_dialogue_created')
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.daily_dialogue_error'))
        isSubmitting.value = false
      }
    }

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/daily-dialogues`
      }
    }

    const getSurveyResults = async () => {
      const meetingDateZeroHours = formatDatePicker(meeting.value.date)
      meetingDateZeroHours.setHours(0, 0, 0, 0)
      const meetingDateNextDay = new Date(meetingDateZeroHours)
      meetingDateNextDay.setDate(meetingDateNextDay.getDate() + 1)

      const query = {
        location: ObjectId(meeting.value.location.value),
        createdAt: { $gte: meetingDateZeroHours, $lt: meetingDateNextDay },
        read: false,
      }

      try {
        const items = await getItems({ collection: 'employee_survey', query, options: { projection : { answer: 1, comment: 1 } } })
        items?.forEach(e => e._id = e._id.toString())

        return items || []
      } catch (error) {
        console.log(error)
      }
    }

    const updateHappinessFromSurvey = async () => {
      isFetchingSurveyResults.value = true
      showComments.value = true
      const categories = { happy: 0, neutral: 0, sad: 0 }

      try {
        const surveyData = await getSurveyResults()
        surveyData.forEach(e => {
          categories[e.answer]++
        })
        comments.value = surveyData
        meeting.value.happy = categories.happy
        meeting.value.neutral = categories.neutral
        meeting.value.sad = categories.sad
        showSuccessMessage(i18n.t('message.survey_results_fetch_successful'))
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.survey_results_fetch_error') })
      } finally {
        isFetchingSurveyResults.value = false
      }
    }

    const validateTab = (tabRef, checkImage = false) => new Promise((resolve, reject) => {
      if (checkImage && daily_dialogue_image_required && !imgData.value.fileInfo) reject()
      tabRef.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })
    const permissions = ref('')
    const activeButton = ref('communications')
    const handleActiveButton = (val) => {
      activeButton.value = val
    }

    const paginatedCustomersOpinions = computed(() => {
      const start = (currentPage.value - 1) * perPage.value
      const end = start + perPage.value
      return customersOpinions.value.slice(start, end)
    })

    const showConfirmationPage = (route, msg) => {
      confirmationPage.value = { route, msg }
    }

    const getHappinessIcons = async (locationId) => {
      if (!locationId) return

      try {
        const query = { _id: ObjectId(locationId) }
        const item = await getItem({ collection: 'location', query })
        if (!item) throw new Error('Item not found')

        useBatteryIcons.value = item.batteryIcons
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.location_fetch_error'))
      }
    }

    return {
      meeting,
      meetingStart,
      meetingEnd,
      updateImprovements,
      updateRecognitions,
      updateHappiness,
      formSubmitted,
      locations,
      workersTotalNotMapped,
      motives,
      welcomeTab,
      happinessTab,
      recognitionsTab,
      improvementsTab,
      finishTab,
      validateTab,
      required,
      integer,
      min_value,
      max_value,
      domains,
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
      metadataNotMapped,
      daily_dialogue_image_required,
      daily_dialogue_date_disabled,
      mood_only_by_survey,
      updateHappinessFromSurvey,
      isFetchingSurveyResults,
      isOnline,
      happyPercentage,
      neutralPercentage,
      sadPercentage,
      handleTabChange,
      communications,
      isLoading,
      openImage,
      actualDateToShow,
      filteredLocation,
      handleActiveButton,
      activeButton,
      customersOpinions,
      permissions,
      information_module,
      sevenDaysBeforeToShow,
      paginatedCustomersOpinions,
      tableColumns,
      sortBy,
      isSortDirDesc,
      opinionId,
      handleView,
      showingMessage,
      perPage,
      currentPage,
      totalOpinions,
      colors,
      showComments,
      comments,
      stateVariants,
      confirmationPage,
      useBatteryIcons,
      batteryIcons
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hover-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.image-container:hover .hover-text {
  opacity: 1;
}

.img-preview {
  max-width: 200px;
  max-height: 200px;
  margin-right: 10px;
  border: none;
}

.no-image {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: .9rem;
}

.optionButton {
  background-color: transparent !important;
  color: grey !important;
  border-color: #D8D6DE !important;
}

.optionButton:hover {
  /* border-color: #50b692 !important; */
  border-color: '#4e8769'!important;
  /* color: #50b692 !important; */
  color: #56616d !important;
  /* background-color: rgba(80, 182, 146, 0.1) !important; */
  background-color: #56616d22  !important;
}

.activeClassButton {
  /* background-color: #50b692 !important; */
  background-color: #56616d !important;
  color: white;
  border-color: transparent !important;
}
</style>

|